export const columnsDataCheck = [
    {
      Header: "NAME",
      accessor: "name",
    },
    {
      Header: "PROGRESS",
      accessor: "progress",
    },
    {
      Header: "QUANTITY",
      accessor: "quantity",
    },
    {
      Header: "DATE",
      accessor: "date",
    },
  ];
  export const act_columnsDataComplex = [
    {
      Header: "BLOCKCHAIN",
      accessor: "chain_id",
    },
    {
      Header: "SIGNER",
      accessor: "signer",
    },
    {
      Header: "TOKEN ID",
      accessor: "tokenId",
    },
    {
      Header: "EVENT",
      accessor: "eventName",
    },
    {
      Header: "RESULT",
      accessor: "eventValue1",
    },
    {
      Header: "TRANSACTION",
      accessor: "transactionHash",
    },
    {
      Header: "TIMESTAMP",
      accessor: "datetime",
    },
    {
      Header: "UAL",
      accessor: "UAL",
    },
  ];
  